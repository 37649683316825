@import './variables';
#codeSearchIcon{
  position: relative !important; top:-7px !important; left:8px !important;
}
// .mdc-checkbox .mdc-checkbox__background{
//   top: 0px; left: 0px;
// }
// .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple{
//   border-radius: 0%;
// }
mat-checkbox{
  // .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  //   top: 2px;
  // }
  .mdc-checkbox__ripple{
    opacity: 0 !important;
  }
  .mdc-checkbox .mdc-checkbox__background {
    top: 2px;
    left: 0px;
    .mdc-checkbox__checkmark {
      color: #fff !important;
    }
  }
  .mdc-checkbox__ripple{
    opacity: 0 !important;
  }
}
.mat-mdc-input-element{
  font: inherit;
    background: transparent;
    color: currentColor;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    box-sizing: content-box;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 7px; 
  min-height: 25px; //create dummy vessel - 23 //commented after applying filter border top is gone
  height: 24px;
}

.mat-mdc-form-field-infix:has(.email-content) {
  height: auto !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}
.mat-mdc-select-arrow svg {
  display: none;
}
.mat-mdc-form-field{
  .mat-mdc-form-field-icon-suffix{
    img.filter-dropdown-icon {
      // display: none;
      width: 16px;
    }
  }
}
.mdc-line-ripple::before {
  border-bottom-width: 0px !important;
}
.mdc-line-ripple::after {
  border-bottom-width: 0px !important;
}
.mat-mdc-standard-chip {
  min-height: 32px;
}
.mat-chip-option .mat-mdc-chip {
  color: white;
  img {
    opacity: 1;
  }
}
.mat-mdc-checkbox {
  &.mat-mdc-checkbox-checked .mdc-form-field label{
    color: #3f51b5
  }  
  .mdc-form-field {
    .mdc-checkbox {
      padding: 0px;
      position:static;
    }
    label {
      padding-left: 25px;
    }
  }
}
mat-option{
  &.mat-mdc-option{
    &.mdc-list-item {
      &.mat-mdc-option-active{
        min-height: 0px;
      }
      min-height: 28px;
      font-size: 0.65625rem;
    }
  }
}   
// .cdk-overlay-pane{
  // width: 20% !important; create vessel pop
  // top: 160px !important;
// }
.mat-mdc-select-panel {
  flex-basis: 100%;
}
mat-card{
  // .mat-mdc-card-content{
    // display: table-footer-group;
  // }
  mat-form-field{
    // .mat-mdc-text-field-wrapper{ 
      // padding : 0px commented since btw filters input no space
    // }
    .mat-mdc-text-field{ 
      padding : 0px
    }
    .mat-datepicker-toggle{
      .mat-mdc-icon-button {
        &.mat-mdc-button-base{
          top: -6px;
          --mdc-icon-button-state-layer-size: 30px;
          padding: 0px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
.mat-mdc-select {
  &.custom {
    border-radius: 3px;
    border: 1px solid $warm-grey;
    background-color: $white;
    .mat-mdc-select {
      &-trigger {
        height: 26px;
        // display: table;
        padding: 0 0 0 3px;
      }
      &-value {
        vertical-align: middle;
      }
    }
  }
  &.custom-candidate {
    border-radius: 0;
    border: 1px solid $warm-grey;
    background-color: $white;
    max-width: 188px;
    .mat-mdc-select {
      &-trigger {
        height: 24px;
        display: table;
        // padding: 0 0 0 3px;
      }
      &-value {
        vertical-align: middle;
      }
    }
  }
}

.mat-mdc-form-field {
  &.custom {
    display: inline-block;
    position: relative;
    outline: 0;
    text-align: left;
    .mat-mdc-form-field {
      &-wrapper {
        border-radius: 3px;
        padding-bottom: 0;
        border: 1px solid $warm-grey;
      }
      &-flex {
        align-items: center;
        padding: 0 3px;
        width: 100%;
        min-height: 24px;
      }
      &-infix {
        padding: 5px 0 !important;
        border: 0;
      }
      &-underline {
        display: none;
      }
      &-should-float {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label {
          display: none;
        }
      }
    }
  }
  &.custom-candidate {
    &.date {
      width: 60% !important;
      display: inline-block;
      padding-right: 5px;
    }
    &.time {
      width: 35% !important;
      display: inline-block;
    }
    &.custom-input-field {
      padding: 0px 10px !important;
      width: 45%;
    }
    display: inline-block;
    position: relative;
    outline: 0;
    text-align: left;
    margin-bottom: 10px; //pre final notice la snooze
    width: 100%;
    .mat-mdc-form-field {
      &-wrapper {
        border-radius: 0;
        padding-bottom: 0;
        border: 1px solid $warm-grey;
      }
      &-flex {
        align-items: center;
        padding: 0 3px;
        width: 100%;
        min-height: 24px;
        height: 24px;
      }
      &-infix {
        padding: 5px 0 !important;
        border: 0;
      }
      &-underline {
        display: none;
      }
      &-should-float {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label {
          display: none;
        }
      }
    }
    .mat-mdc-form-field-subscript-wrapper{
      &.mat-mdc-form-field-bottom-align{
        display: none;
      }
    }
    .mat-mdc-text-field-wrapper { 
      padding: 0px;
      border: 1px solid #888888;
      border-radius: 0;
    }
    .mat-mdc-icon-button{
      position: absolute;
      right: 7px;
      top: 6px;
      width: 2px;
      height: 2px;
      padding-left: 0px !important
    }
    .mat-mdc-button>.mat-icon{
      font-size: 11px;  //close icon of date-picker
      top: 3px;
    }
  }
}

.ng-select {
  &.custom {
    padding: 0 !important;
    .ng-select {
      &-container {
        border-radius: 3px;
        border: 1px solid $warm-grey;
        background-color: $white;
        min-height: inherit !important;
        &::after {
          content: none !important;
          display: none;
        }
        >div {
          padding: 3px;
          margin: 0;
          height: 24px;
          border: 0;
        }
        .ng-input {
          bottom: inherit;
          padding: 0 4px;
        }

        .ng-clear-wrapper {
          display: none;
        }
      }
    }
    .ng-dropdown-panel {
      &.ng-select-bottom {
        top: inherit;
      }
      &.ng-select-top {
        bottom: 100%;
      }
    }
  }
  &.search {
    .ng-arrow-wrapper {
      display: block;
      width: 20px;
      position: relative;
    }
    &.ng-select {
      &-searchable {
        .ng-arrow {
          position: initial;
          border: 0;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            z-index: 3;
            background-size: contain;
            top: 5px;
            height: 16px;
            width: 16px;
            background-image: url('/assets/icons/search.svg');
          }
        }
      }
    }
  }
}

.PointOfContact .mdc-text-field{
  padding: 0px;
}
div.mat-mdc-select-panel { //teu filter in vessel
  min-width: 320px;
}
.paint-impact {
  .errorMargin{
    &.col-8{
      .mat-mdc-select-arrow svg{
        display: inline;
      }
    }
  }
}
.dummy-dashboard{
  .mat-mdc-form-field{
    .mat-mdc-form-field-icon-suffix{
      .mat-mdc-button{
        position: absolute;
        right: 4px;
        top: -18px;
      }
      mat-datepicker-toggle{
        position: absolute;
        top: -5px;
        left: -28px;
      }
    }
  }
}
.typeFormGrp{ //dictionary-detail-component
  mat-form-field{
    .mdc-text-field{
      // padding: 0px 2px 8px 1px;
      padding: 0px;
      .mat-mdc-form-field-flex{
      // .mdc-line-ripple{ 
        border-bottom: 1px solid #0000006b; 
      }
      // .mdc-text-field--filled {
      //   border-bottom: 1px solid #0000006b; 
      // }
    }
  }
}
.candidate-card{
  .sap-cust-vend-code{
    mat-form-field{
      padding-top: 9px;
    }
    mat-select{ 
      .mat-mdc-select-arrow svg { display: inline; } 
    }
  }
  .dictionary-strings{
    .row{
      padding-bottom: 9px;
      mat-select{ 
        .mat-mdc-select-arrow svg { display: inline; } 
      }
    }
  }
}