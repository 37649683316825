// __<<ngThemingMigrationEscapedComment0>>__
@import '~@ng-select/ng-select/themes/material.theme.css';
@import './styles/material_theming';
@import './styles/variables';
@import 'bootstrap/scss/grid';
@import '~@maersk-global/insightshub-embed-common/src/css/sidetab.css';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/MaterialIcons/MaterialIcons-Regular.eot'), local('Material Icons'), local('MaterialIcons-Regular'),
    url('./fonts/MaterialIcons/MaterialIcons-Regular.woff2') format('woff2'),
    url('./fonts/MaterialIcons/MaterialIcons-Regular.woff') format('woff'),
    url('./fonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: rem(24); // Preferred icon size
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr; // __<<ngThemingMigrationEscapedComment1>>__
  -webkit-font-smoothing: antialiased; // __<<ngThemingMigrationEscapedComment2>>__
  text-rendering: optimizeLegibility; // __<<ngThemingMigrationEscapedComment3>>__
  -moz-osx-font-smoothing: grayscale; // __<<ngThemingMigrationEscapedComment4>>__
  font-feature-settings: 'liga';
}

@font-face {
  font-family: 'MaerskText';
  font-weight: normal;
  src: url('./fonts/MaerskText/MaerskText-Regular.eot'),
    url('./fonts/MaerskText/MaerskText-Regular.woff') format('woff'),
    url('./fonts/MaerskText/MaerskText-Regular.woff2') format('woff2'),
    url('./fonts/MaerskText/MaerskText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MaerskText';
  font-weight: bold;
  src: url('./fonts/MaerskText/MaerskText-Bold.eot'), url('./fonts/MaerskText/MaerskText-Bold.woff') format('woff'),
    url('./fonts/MaerskText/MaerskText-Bold.woff2') format('woff2'),
    url('./fonts/MaerskText/MaerskText-Bold.ttf') format('truetype');
}

@import './styles/cdk_overlay';
@import './styles/ngx_table';
@import './styles/forms';

* {
  box-sizing: border-box;
}

body {
  margin: 0;

  // .side-nav {
  //   padding-top: 40px;
  // }

  font-family: 'MaerskText', sans-serif;
  background: $white-grey-two;

  * {
    font-size: rem(10.5);
  }
}
.as-sidetab {
  position: fixed;
  top: 50%;
  right: 0;
  width: 650px;
  height: 580px;
  transform: translate(100%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
  z-index: 10001;
  will-change: transform;
}
.as-sidetab-button {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left top;
  min-width: 100px;
  max-width: 540px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
  background-color: #00243d;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  outline: none;

  .as-sidetab-button-text {
    flex: 1;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .as-sidetab-button-icon {
    width: 18px;
    height: 18px;
    /* font-size: 24px; */
    transform: rotate(90deg);
    margin-right: 5px;
    position: relative;
    order: -1;
  }

  .as-sidetab-button-text {
    flex: 1;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .as-sidetab-button-icon {
    width: 18px;
    height: 18px;
    /* font-size: 24px; */
    transform: rotate(90deg);
    margin-right: 5px;
    position: relative;
    order: -1;
  }
}
a {
  color: $seafoam-blue-two;
  text-decoration: none;
  cursor: pointer;

  &[href^='mailto:'] {
    color: $dusk-blue !important;
    text-decoration: none;
  }

  &:visited {
    color: $seafoam-blue-two;
    text-decoration: none;
  }
}

.highcharts-title {
  font-size: rem(22) !important;
}

.mat-close-icon {
  background: none !important;
  border: 0 !important;
  border-color: $white !important;
  cursor: pointer;
  padding-top: 5px;
  float: right;
  text-decoration: none !important;
  outline: none;
}

.text-right {
  text-align: right;
}

.warm-grey {
  color: $warm-grey;
}

.text-green {
  color: $dark-green-blue;
}

textarea {
  font-size: rem(12) !important;
  font-family: inherit;
}

.text-warm-grey {
  color: $warm-grey !important;
}

.mat-mdc-form-field-error,
.asterisk {
  color: $blood-red;
}

.black {
  color: $black;
}

.text-black {
  color: $black;
}

.text-mango {
  color: $mango;
}

.text-light-red {
  color: $light-red;
}

.text-tomato {
  color: $tomato;
}

.text-blood {
  color: $blood-red;
}

.text-dusk-blue {
  color: $dusk-blue !important;
}

.text-seafoam-blue {
  color: $seafoam-blue;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.font-bold {
  font-family: 'MaerskText', sans-serif;
  font-weight: bold;
}

.inline-block {
  display: inline-block;
}

.space {
  padding-left: 4px;
}

.padding-top {
  padding-top: 10px;
}

.space-data {
  padding-left: 8px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-border-bottom {
  border-bottom: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid $white-two !important;
}

.border-top {
  border-top: 1px solid $white-two !important;
}

.trade-restriction-container {
  .mat-mdc-dialog-container {
    padding: 0;
    overflow: auto;
  }
}

.dialog-container {
  .mat-mdc-dialog-container {
    padding: 0;
    overflow: hidden; // overflow: auto; // commented to remove scroll bars
  }
}

.changelog-container {
  .mat-mdc-dialog-container {
    background: $white-grey;
  }
}

.mat-ripple-element,
.mat-ripple {
  transition-duration: 0ms !important;
}

.no-hover-effect {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-focus-overlay {
    background-color: transparent !important;
  }
}

.primary-tooltip {
  background-color: $black-two;
}

.tooltip-color {
  background-color: #ffffbf;
  border: 1px solid $lighter-orange !important;
  color: $black !important;
  white-space: pre-line;
}

.text-color {
  color: $seafoam-blue-three;
}

//.tooltiptext{
// color: black;
//}

.replace-dialog-container {
  .mat-mdc-dialog-container {
    padding: 0; // overflow: auto; // commented to remove scroll bars
  }
}

ng-dropdown-panel.a3-column-filter {
  width: auto !important;

  .ng-dropdown-header {
    padding: 3px !important;
  }
}

champs-vessel-redesign-deployment mat-form-field .mat-mdc-form-field-underline {
  height: 0 !important;
}

.a3-datefilter {
  margin-top: 10px;
  margin-left: 10px;
}

