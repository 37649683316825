// bootstrap grid breakpoints
$base-font-size: 16;
$grid-breakpoints: (xs: 0, sm: 375px, md: 768px, lg: 989px, xl: 1350px);
$container-max-widths: (sm: 735px, md: 956px);
$grid-gutter-width: 32px !default;
// colors
$grid-grey: #ececec !default;
$white-grey-black: #999999 !default;
$grid-border: rgba(37, 29, 29, 0.12) !default;
$div-grey: rgba(224, 224, 224, 0.15) !default;
$grey: #90949C;
$dark-grey: #616771;
$silver: #c0c0c0;
$white-border: #cccccc;
$shadow-color: rgba(0, 0, 0, 0.5) !default;
$blood-red: #B1453F !default;
$disabled-grey: #ebebe4 !default;
$white-grey: #ededed !default;
$white-grey-two: #fafafa !default;
$lighter-grey-two: #dadada !default;
$lighter-grey: #eaeaea !default;
$black: #282828 !default;
$light-black: #313131 !default;
$black-two: #333333;
$white: #ffffff !default;
$seafoam-blue: #64b2d4 !default;
$pinkish-grey: #bbbbbb !default;
$light-grey: rgba(242, 242, 242, 1);
$warm-grey: #888888 !default;
$trans-grey: hsla(0, 0%, 74%, 0.5) !default;
$light-grey-green: #a3dcaf !default;
$marine-blue: #003e5e !default;
$seafoam-blue-two: #3486b5 !default;
$seafoam-blue-three: #169bd7 !default;
$white-two: #f0f0f0 !default;
$beige: #e6d8b8 !default;
$red-brown: #92251a !default;
$tomato: #d62d23 !default;
$light-red: #ff4142 !default;
$mango: #ff9b1e !default;
$sun-yellow: #ffd21e !default;
$dusk-blue: #266d8c !default;
$pale: #fff6d2 !default;
$dark-green-blue: #195a3c !default;
$green-two: #2fc608 !default;
$light-grey-green: #a3dcaf !default;
$mds-blue: #00243D !default;
$m-success: #57cd6e !default;
$m-success-bg: #fcfff5 !default;
$m-warn: #ffa73b !default;
$m-warn-bg: #fffaf3 !default;
$m-error: #de574f !default;
$m-error-bg: #fff6f6 !default;
$m-info: #64b2d4 !default;
$m-info-bg: #f8ffff !default;
/** colors for color coding */

$grey: #edf0f2;
$tomato: #d62d23;
$mango: #ff9b1e;
$border-grey: #f1f1f1;
/** red */

$dark-red: #C74E46;
$normal-red: #DE574F;
$light-red: #E27069;
$lighter-red: #E78984;
/** orange */

$dark-orange: #E59534;
$normal-orange: #FFA73B;
$light-orange: #FFB458;
$lighter-orange: #FFC176;
/** green */

$dark-green: #2dba5e;
$normal-green: #55c77d;
$light-green: #5ad585;
$lighter-green: #a3dcaf;
// shadows
$shadow-one: rgba(0, 0, 0, .24) !default;

//market-rate
$rate-green: #008000;
$red: #FF0000;
$border-color: #797979;
$font-family-monospace: 'Monospace', monospace;
@import './rem';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../node_modules/bootstrap/scss/mixins/grid-framework';
@import '../../node_modules/bootstrap/scss/mixins/grid';
@import '../../node_modules/bootstrap/scss/mixins/float';
@import '../../node_modules/bootstrap/scss/mixins/_text-truncate.scss';
// @import 'bootstrap/scss/mixins/text-truncate';
@import '../../node_modules/bootstrap/scss/mixins/text-emphasis';
@import '../../node_modules/bootstrap/scss/mixins/text-hide';
@import '../../node_modules/bootstrap/scss/mixins/hover';
@import '../../node_modules/bootstrap/scss/mixins/deprecate';
@import '../../node_modules/bootstrap/scss/utilities/flex';
@import '../../node_modules/bootstrap/scss/utilities/float';
@import '../../node_modules/bootstrap/scss/utilities/text';
