@import './variables';

.ngx-datatable {
  &.fixed-dtheader {
    transform: inherit !important;

    .datatable {
      &-header {
        position: fixed;
        top: 50px;
        z-index: 1000;
      }
    }
  }

  &.mobile {
    .datatable-scroll {
      width: 100% !important;
      height: 0px !important; // to solve the bug for scroll-bar every where in css;

      .datatable-row-detail {
        .row-detail {
          width: 100%;
          border: 0.5px solid $warm-grey;
          padding: 10px 10px 10px 30px;
        }
      }
    }
  }

  &.dashboardnotices {
    height: 350px !important;

    .datatable-scroll {
      height: 0px !important;
    }

    .datatable-body-row {
      border-bottom: 1px solid $white-grey;

      &:hover {
        background-color: $white-grey;
      }
    }

    .scroll-vertical {
      .datatable-body {
        overflow-y: auto;
        overflow-x: hidden !important;
      }
    }
  }

  &.dashboardsections {
    height: 250px !important;

    .datatable-scroll {
      height: 0px !important;
    }

    .datatable-body-row {
      border-bottom: 1px solid $white-grey;

      &:hover {
        background-color: $white-grey;
      }
    }
  }

  &.dashboardissues {
    .datatable-scroll {
      height: 0px !important;
    }

    .datatable-body-row {
      border-bottom: 1px solid $white-grey;

      &:hover {
        background-color: $white-grey;
      }
    }
  }

  &.dashboardwarrisk {
    .datatable-scroll {
      height: 0px !important;
    }

    .datatable-body-row {
      border-bottom: 1px solid $white-grey;

      &:hover {
        background-color: $white-grey;
      }
    }
  }

  &.dashboardbunkeralerts {
    .datatable-scroll {
      height: 0px !important;
    }

    .datatable-body-row {
      border-bottom: 1px solid $white-grey;

      &:hover {
        background-color: $white-grey;
      }
    }
  }

  &.dashboarddailyhsalerts {
    // .datatable-scroll {
    //   width: 100%;
    //   height: 0px !important;
    // }

    height: 300px;
    text-overflow: ellipsis;
    overflow: scroll;
    overflow-x: auto !important;

    .datatable-body {
      overflow-x: hidden !important;
    }

    .datatable-body-row {
      border-bottom: 1px solid $white-grey;
      // overflow-wrap: break-word;

      &:hover {
        background-color: $white-grey;
      }
    }
  }

  &.candidate {
    .datatable-scroll {
      width: 100% !important;
      height: 0px !important;

      .datatable-row-detail {
        border: 1px solid $lighter-grey;
        box-shadow: 3px 5px $lighter-grey;

        .row-detail {
          width: 100%;
          background: $white;
          padding: 10px;
        }
      }
    }
  }

  &.service-repository {
    .datatable-scroll {
      width: 100% !important;
      height: 0px !important;

      .datatable-row-detail {
        border: 1px solid $lighter-grey;
        box-shadow: 3px 5px $lighter-grey;

        .row-detail {
          width: 100%;
          background: $white;
          padding: 10px;
        }
      }
    }
  }

  &.dashboardalert {
    .datatable-scroll {
      width: 100% !important;
      height: 0px !important;

      .datatable-row-detail {
        .row-detail {
          width: 100%;
          background: $white;
          padding: 10px;
        }
      }
    }

    .datatable-body-row {
      border-bottom: 1px solid $white-grey;

      &:hover {
        background-color: $white-grey;
      }
    }
  }

  &.service-repository {
    .datatable-scroll {
      width: 100% !important;
      height: 0px !important;

      .datatable-row-detail {
        border: 1px solid $lighter-grey;
        box-shadow: 3px 5px $lighter-grey;

        .row-detail {
          width: 100%;
          background: $white;
          padding: 10px;
        }
      }
    }
  }

  .datatable {
    &-header {
      color: $white !important;
      margin-bottom: 4px;
      background-color: $light-black;
      width: 23px;
      height: 32px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: -0.1px;
      padding: 6px;

      .datatable-header-cell {
        .sort-btn {
          //     display: none !important; //uncomment if u wanna remove sorting arrows
          line-height: inherit !important;
        }
      }
    }

    &-body {
      overflow-x: hidden;

      &-row {
        background-color: $white;
        margin-top: 2px;
        padding: 6px;

        > div {
          /*border-bottom: 1px solid $seafoam-blue;*/
          margin-top: 2px;
        }
      }

      &-cell {
        &.sort-active {
          color: $black !important;
        }

        &-label {
          img,
          .email-checkbox {
            float: right;
            margin-right: -4px;
          }
        }
      }

      .empty-row {
        text-align: center;
        font-size: 12px;
        padding: 5px;
      }
    }

    &-footer {
      &-inner {
        color: $dusk-blue;
        text-align: center;
        justify-content: center;

        span {
          display: inline-block;
          cursor: pointer;
          padding: 10px;
          border: 1px solid transparent;
          border-radius: 5px;
        }
      }
    }
  }
}
