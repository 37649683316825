@import './variables';

.mat-form-field.custom-candidate .mat-form-field-wrapper {
  border-radius: 0;
  padding-bottom: 0;
  border: 1px solid #888888;
}
mat-datepicker-toggle{
  font-size:15px !important;
  .mat-mdc-icon-button svg, .mat-mdc-icon-button img {
    width: 11px;
    height: 11px;
    position: absolute;
    top: 6px;
  }
}
.mat-mdc-button-touch-target{
  width: 10px !important
}
.notices-dashboard-recieve{
  mat-form-field{
    .mat-mdc-text-field-wrapper {
      padding: 0px;
    }
    .mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 24px;
    }
  }
}

.update-form-container{
  mat-card{
    mat-form-field{
      .mat-mdc-form-field-icon-suffix{
        padding: 0px;
        .mat-datepicker-toggle{
          .mat-mdc-icon-button.mat-mdc-button-base {
            top: 3.1px;
            --mdc-icon-button-state-layer-size: 30px;
            padding: 0px;
            width: 16px;
            height: 16px;
            right: 10px;
          }
        }
      }
    }
  }   
}

.owner-dropdown {
  margin-left: -16px;
}

.border-radius5 {
  border-radius: 5px !important;
}

.add-schedule-dialog {
  .box-border {
    border: 1px solid $black !important;
    overflow: auto;
    height: 200px;
  }

  .heading {
    width: 41%;
    display: inline-block;
    color: $warm-grey;
    vertical-align: top;
  }

  .heading-date {
    width: 10%;
    display: inline-block;
    color: $warm-grey;
  }

  .search-heading {
    width: 41%;
    display: inline-block;
    vertical-align: top;
  }

  .red-span {
    color: $blood-red;
  }

  .red-span-service {
    color: $blood-red;
    display: inline-block;
    width: 59%;
    text-overflow: ellipsis;
  }

  .service-validation {
    text-align: right;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 6%;
  }

  .value {
    display: inline-block;
    width: 59%;
    color: $black;
    text-overflow: ellipsis;

    .service-date-picker {
      width: 100%;
    }

    .service-date-picker {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      mat-form-field.mat-mdc-form-field.mat-primary.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-hide-placeholder._mat-animation-noopable {
        width: 45%;
        color: #3486b5;
        border: 0;
        display: inline-block;
        padding: 0 2px;
        max-height: 24px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-wrapper {
        padding: 0 !important;
        max-height: 24px;
        border: 0;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-flex {
        max-height: 24px;
        border: 0;
        padding: 0;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix {
        border: 0;
        height: 24px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-suffix.ng-star-inserted {
        width: 0px;

      }

      .mat-datepicker-toggle .mat-mdc-icon-button {
        position: relative;
        left: -62px;
        padding-left: 60px;
        border-radius: 0px;
        bottom: -5px;
      }

      .togglepicker svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
        display: none;
      }
    }
  }

  .value-dates {
    display: inline-block;
    width: 45%;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .border-line {
    padding: 5px 0;
    border-bottom: 1px solid $white-two;
  }

  .main-border-line {
    padding-bottom: 7%;
    border-bottom: 1px solid $white-two;
  }

  .add-schedule-dialog-alignment {
    margin: 11px;
  }

  .add-schedule-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4%;
  }

  .add-schedule-dialog-buttons {
    margin-left: 38% !important;
    margin-top: 10px !important;
  }

  .dialog-footer-content-right {
    display: flex;
    justify-content: right;
    padding: 0 10px;
  }

  .import-dates-section {
    width: 45% !important;
    display: inline-block !important;
  }

  .mr-s {
    margin-right: 0.8rem;
  }

  .ml-s {
    margin-left: 0.8rem;
  }

  .close {
    text-align: right;
    width: 67%;
    display: inline-block;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  mat-form-field.ng-invalid.import-to-date .mat-form-field-flex {
    padding-right: 0 !important;
    padding-left: 0 !important;
    border: 1px solid $danger;
    border-radius: 2px;
  }
  .mat-mdc-form-field {
    &-flex {
      padding-right: 5px;
      padding-left: 5px;
      border: 1px solid $warm-grey;
      border-radius: 2px;
    }
    .mdc-text-field{
      padding: 0px;
    }

    &-infix {
      border: 0;
    }

    &-underline {
      display: none;
    }

    &-should-float {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label {
        display: none;
      }
    }
    &-icon-suffix{
      .mat-mdc-button.mat-mdc-button-base{
        height: 11px;
      }
    }
  }
}

.add-service-dialog {
  .ng-select {
    &.filter {
      width: 160px;
      margin-right: 5px;
      background-color: $white;
      border: 1px solid $white-grey;
      padding: 0 !important;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 5px;
      zoom: 1;

      .ng-select-container {
        min-height: 24px;
        background-color: $white;

        &::after {
          content: none !important;
          display: none;
        }

        >div {
          padding: 4px 3px;
          margin: 0;
          height: 22px;
          border: 0;
        }

        .ng-input {
          bottom: inherit;
          padding: 0 4px;

          input {
            cursor: text;
          }
        }

        .ng-placeholder {
          bottom: 4px;
        }

        .ng-value {
          display: inline;
        }

        .ng-arrow-wrapper {
          display: none;
        }

        .ng-clear-wrapper {
          display: none;
        }
      }

      .ng-dropdown-panel {
        &.ng-select-bottom {
          top: inherit;
        }

        &.ng-select-top {
          bottom: 100%;
        }
      }
    }
  }

  .table {
    border-bottom: 0 solid $lighter-grey;
    width: 100%;
    display: inline-block;

    &-space {
      display: inline-block;
      width: 20px;
    }

    &-gap {
      width: 5%;
      position: relative;
      display: inline-block;
    }

    &-heading {
      padding: 5px 0;
      border-bottom: 1px solid $lighter-grey;
      color: $warm-grey;
    }

    &-proforma {
      position: relative;
      width: 20%;
      display: inline-block;
      text-align: left;
    }

    &-status {
      position: relative;
      width: 15%;
      text-align: left;
      display: inline-block;
    }

    &-gsis {
      position: relative;
      width: 14%;
      text-align: left;
      display: inline-block;

      .right {
        width: 40%;
        display: inline-block;
        text-align: right;
      }
    }

    &-valid-from {
      position: relative;
      width: 16%;
      text-align: left;
      display: inline-block;
    }

    &-valid-to {
      position: relative;
      width: 15%;
      text-align: left;
      display: inline-block;
    }

    &-values {
      border-bottom: 1px solid $lighter-grey;
      padding-bottom: 1%;
    }

    &-icons {
      margin-bottom: 2%;
    }
  }

  .search-heading {
    width: 11%;
    display: inline-block;
    vertical-align: top;
  }

  .red-span {
    color: $blood-red;
  }

  .red-span-service {
    color: $blood-red;
    display: inline-block;
    width: 50%;
    text-overflow: ellipsis;
  }

  .service-validation {
    text-align: right;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 6%;
  }

  .value {
    display: inline-block;
    width: 50%;
    color: $black;
    text-overflow: ellipsis;
  }

  .main-border-line {
    padding-bottom: 2%;
  }

  .add-service-dialog-alignment {
    margin: 11px;
  }

  .add-service-header {
    width: 100%;
    display: inline-block;
  }

  .add-service-dialog-buttons {
    width: 100%;
    text-align: right !important;
    margin-top: 10px !important;
  }

  .add-service-dialog-title {
    width: 40%;
    display: inline-block;
  }

  .close {
    text-align: right;
    width: 60%;
    display: inline-block;
  }

  .schedule-div {
    padding: 5px 0;
    border-bottom: 1px solid #eaeaea;
  }

  .mat-mdc-form-field {
    &-flex {
      padding-right: 5px;
      padding-left: 5px;
      border: 1px solid $warm-grey;
      border-radius: 2px;
    }

    &-infix {
      border: 0;
    }

    &-underline {
      display: none;
    }

    &-should-float {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label {
        display: none;
      }
    }
  }
}

.compare-candidate-approval {
  padding: 16px;
}


.button {
  &-disabled {
    cursor: wait !important;
    background-color: $lighter-grey-two !important;
  }
}

.approval-letter-decline {
  padding: 16px;

  &-title {
    font-weight: bold;
  }

  &-reason {
    margin-top: 16px;

    &-box {
      textarea {
        width: 100%;
      }
    }
  }

  &-buttons {
    float: right;
    padding-top: 12px;
    padding-bottom: 12px;

    &-cancel {
      background-color: $white !important;
      color: $black !important;
    }

    &-decline {
      background-color: $tomato !important;
    }
  }

}

.upload-candidate {
  .upload {
    &-header {
      height: 35px;
      padding-bottom: 15px !important;
    }

    &-div {
      &-comp {
        .file {
          &-upload {
            padding: 0 !important;

            &-title {
              display: none !important;
            }

            &-drop {
              width: 300px !important;
              height: 150px !important;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}

.fixture-restrictions-create {
  background: $white;

  &-header {
    line-height: 32px;
    padding-left: 16px;
    background: $white-two;
  }

  &-form {
    padding: 8px 16px;

    .title {
      width: 43%;
      display: inline-block;
      line-height: 32px;
    }

    .form-input {
      width: 57%;
      display: inline-block;
      line-height: 32px;
      padding-right: 16px;

      .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
          padding: 0px;
        }
        &-flex {
          padding-right: 5px;
          padding-left: 5px;
          border: 1px solid $warm-grey;
          border-radius: 2px;
        }

        &-infix {
          border: 0;
        }
        &-icon-suffix{
          white-space: nowrap;
          flex: none; 
          .custom-close-icon-button{
            height: 12px !important;
            min-width: 12px;
            padding: 0px;
            right: -13px;
            top: 6px;
          }
          .mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
            --mdc-icon-button-state-layer-size: 24px;
          }
        } 
        &-underline {
          display: none;
        }
        .mat-mdc-form-field-subscript-wrapper{
          display: none;
        }
        &-should-float {
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-label {
            display: none;
          }
        }
        mat-select{ 
          .mat-mdc-select-arrow svg { 
            display: inline; 
          }
        }
      }
    }

    .form-error {
      margin-left: 138px;
    }

    input {
      &.input-box {
        width: 100%;
        border: 1px solid $warm-grey;
        border-radius: 3px;
        height: 24px;
      }
    }

    .comment {
      margin-top: 16px;

      &-text {
        line-height: 24px;
      }

      &-input {
        textarea {
          &.text-area {
            width: 65%;
            border: 1px solid;
            border-radius: 4px;
          }
        }
      }
    }
  }

  &-footer {
    padding: 8px 16px;
    line-height: 32px;

    .delete-restriction {
      color: $tomato;
      cursor: pointer;
    }

    .save-form {
      background: $seafoam-blue;
      padding: 8px 16px;
      border-radius: 3px;
      color: $white;
      margin-left: 8px;
    }

    .disabled {
      cursor: not-allowed;
      background: $warm-grey;
    }
  }
}

.cargo-restrictions-create {
  background: $white;

  &-header {
    line-height: 32px;
    padding-left: 16px;
    background: $white-two;
  }

  &-form {
    padding: 8px 16px;

    .col {
      padding: 6px 0;
    }

    .title {
      width: 35%;
      display: inline-block;
      line-height: 30px;
      vertical-align: top;
      /* padding: 8px; */
    }

    .form-input {
      width: 65%;
      display: inline-block;
      line-height: 0;
      padding-right: 16px;

      .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
          padding: 0px;
        }
        &-flex {
          padding-right: 5px;
          padding-left: 5px;
          border: 1px solid $warm-grey;
          border-radius: 2px;
        }

        &-infix {
          border: 0;
        }

        &-underline {
          display: none;
        }

        &-should-float {
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-label {
            display: none;
          }
        }
        mat-select{ 
          .mat-mdc-select-arrow svg { 
            display: inline; 
          }
        }
      }

      textarea {
        &.text-area {
          width: 100%;
          border: 1px solid;
          border-radius: 4px;
        }
      }
    }

    .form-error {
      margin-left: 164px;
    }

    input {
      &.input-box {
        width: 100%;
        border: 1px solid $warm-grey;
        border-radius: 3px;
        height: 24px;
      }
    }

    .mat-mdc-form-field {
      &-wrapper {
        padding: 0;
      }
    }
  }

  &-footer {
    padding: 8px 16px;
    line-height: 32px;

    .delete-restriction {
      color: $tomato;
      cursor: pointer;
    }

    .save-form {
      background: $seafoam-blue;
      padding: 8px 16px;
      border-radius: 3px;
      color: $white;
      margin-left: 8px;
    }

    .disabled {
      cursor: not-allowed;
      background: $warm-grey;
    }
  }
}

.trade-restrictions-create {
  background: $white;
  height: 278px;

  .accordion-popup {
    width: 260px;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0 2px 4px 0 $black;
    background-color: $white;
    color: $black;
    position: absolute;
    left: 140px;
    z-index: 100;
    top: 30px;

    &-for-actual-delivery {
      margin-left: -25px;
    }
  }

  .each-port-region {
    border-bottom: 1px solid $lighter-grey;
    line-height: 26px;

    .each-country {
      margin-left: 18px;
    }

    .each-port {
      margin-left: 45px;
      cursor: default;

      &-delete {
        color: $tomato;
        cursor: pointer;
        float: right;
      }
    }

    .port-delete {
      float: right;
    }
  }

  .ranges-arrow {
    height: 20px;
    width: 20px;
    margin-bottom: -6px;
  }

  .cur {
    cursor: pointer;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;

    &.cursor-text {
      cursor: text !important;
    }

    .show-more-icon {
      position: absolute;
      top: 3px;
      right: 0;

      img {
        height: 15px;
        width: 17px;
      }
    }
  }

  .option {
    padding: 2px;
  }

  &-header {
    line-height: 32px;
    padding-left: 16px;
    background: $white-two;
  }

  &-form {
    padding: 8px 16px;

    .title {
      width: 25%;
      display: inline-block;
      line-height: 30px;
      vertical-align: top;
      /* padding: 8px; */
    }

    .comments {
      margin-top: 43px;
    }

    .form-error {
      margin-left: 118px;
    }

    .form-input {
      width: 75%;
      display: inline-block;
      line-height: 32px;
      padding-right: 16px;

      .mat-mdc-form-field {
        &-flex {
          padding-right: 5px;
          padding-left: 5px;
          border: 1px solid $warm-grey;
          border-radius: 2px;
        }

        &-infix {
          border: 0;
        }

        &-underline {
          display: none;
        }

        &-should-float {
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-label {
            display: none;
          }
        }
      }

      textarea {
        &.text-area {
          width: 100%;
          border: 1px solid;
          border-radius: 4px;
        }
      }
    }

    input {
      &.input-box {
        width: 100%;
        border: 1px solid $warm-grey;
        border-radius: 3px;
        height: 24px;
      }
    }
  }

  &-footer {
    padding: 5px 16px;
    margin-top: 29px;
    line-height: 32px;

    .delete-restriction {
      color: $tomato;
      cursor: pointer;
    }

    .save-form {
      background: $seafoam-blue;
      padding: 8px 16px;
      border-radius: 3px;
      color: $white;
      margin-left: 8px;
    }

    .disabled {
      cursor: not-allowed;
      background: $warm-grey;
    }
  }

}

.add-port-dialog {
  position: relative;

  &-search-icon {
    position: absolute;
    top: 18px;
    right: 16px;
  }

  .mat-mdc-dialog-container {
    overflow: hidden;
  }

  .range-or-country {
    font-weight: bold;
    background-color: $lighter-grey-two;
  }


  .changelog-container {
    .mat-mdc-dialog-container {
      background: #ededed;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
    margin-top: 3px;

    &-wrapper {
      padding: 0;
    }

    &-flex {
      border: solid 1px $warm-grey;
      border-radius: 3px;
    }

    &-infix {
      border: 0;
      padding: 0.35em 0;

      input {
        padding-left: 12px;
        padding-right: 12px;
        line-height: 12px;
      }
    }

    &-label-wrapper {
      display: none;
    }

    &-underline {
      display: none;
    }

    textarea {
      &.mat-mdc-input-element {
        padding: 2px 16px;
      }
    }
  }

  padding: 16px !important;

  &-buttons {
    &-cancel {
      position: absolute !important;
      left: 0;
      color: $black;
    }

    &-add {
      position: absolute !important;
      right: 16px;
    }

    margin-top: 268px;
  }

  .ng-select {
    &.custom {
      .ng-option {
        padding: 0 !important;
      }

      .ng-select-container {
        width: 90%;
        height: 30px;
        border-radius: 3px;
        border: 1px solid $warm-grey;
        background-color: $white;

        &::after {
          content: none !important;
          display: none;
        }

        >div {
          padding: 1px 3px;
          margin: 0;
          height: 22px;
          border: 0;
        }

        .ng-placeholder {
          position: absolute;
          top: 14px;
        }

        .ng-input {
          bottom: inherit;
          padding: 0 4px;
          position: absolute;
          top: 14px;
          cursor: text !important;
        }

        .ng-arrow-wrapper {
          display: none;
        }

        .ng-clear-wrapper {
          display: none;
        }

        .ng-value-container {
          .ng-value {
            display: none !important;
          }
        }
      }

      .ng-dropdown-panel {
        &.ng-select-bottom {
          top: inherit;
          box-shadow: none !important;
        }

        &.ng-select-top {
          bottom: 100%;
        }
      }

      .country-name {
        margin-left: 70px;
      }
    }

    &.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
      font-size: inherit;
      font-weight: 500;
    }

    &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
      cursor: none;
    }
  }

}

.confirmation-dialog {
  &-close {
    text-align: right;
  }

  &-text-padding-top {
    padding-top: 3px;
  }

  &-text-padding-left {
    padding-left: 10px;
  }

  &-msg-margin {
    margin-top: 4%;
  }

  &-right-zero {
    text-align: right;
    padding-right: 0px !important;

    &-close {
      float: right;
    }
  }

  &-left-zero {
    text-align: right;
    padding-left: 0px !important;
  }

  &-selectbtn {
    margin-left: 78%;
  }

  .confirmation-dialog-alignment {
    margin: 11px;

    &-error {
      color: $tomato;
    }
  }

  .confirmation-dialog-mat {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label-content {
      padding-bottom: 1% !important;
    }
  }

  .confirmation-dialog-header {
    margin-bottom: 4%;

    &-text {
      padding-bottom: 3%;
    }
  }

  .confirmation-dialog-buttons {
    margin-left: 38% !important;
    margin-top: 10px !important;
  }

  .confirmation-dialog-cutvsl-buttons {
    margin-left: 25% !important;
    // margin-top: 10px !important;
  }

  .cutvsl-popup-heading {
    width: 100%;
    height: 30px;
    background-color: $light-grey;
    padding-top: 7px;
    margin-bottom: 10px;
  }

  .confirmation-dialog-back-buttons {
    margin-left: 66% !important;
    margin-top: 10px !important;
  }

  .check-vsl {
    width: 100% !important;
  }

  .confirmation-popup-cancel-button {
    border-radius: 5px;
    border: 1px solid grey;
    max-width: 48%;

  }

  .confirmation-popup-validate-button {
    border-radius: 5px;
    margin-left: 4%;
    max-width: 48%;
  }

  .confirmation-button-alingment {
    margin-top: 4%;

  }

  .mat-icon-button-custom {
    padding: 0;
    min-width: 0;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    line-height: 25px;
    border-radius: 50%;
  }

  .service-create {
    padding: 16px;

    .service {
      &-title {
        color: $black;
        font-size: rem(20);
        margin-bottom: 20px;
      }

      &-reason {
        margin-top: 20px;

        textarea {
          margin: 8px 0;

          &.text-area {
            width: 100% !important;
            font-size: rem(12) !important;
            overflow: auto !important;
            font-family: inherit !important;
          }
        }
      }

      &-teu {
        .input-teu {
          width: 30%;
          display: inline-block;
        }

        .text-teu {
          width: 5%;
          display: inline-block;
        }

        .symbol-teu {
          width: 10%;
          display: inline-block;
        }
      }
    }

    .line {
      padding: 4px 0;
      line-height: 32px;

      .headings {
        width: 40%;
        display: inline-block;

        &-teu {
          width: 33%;
          display: inline-block;
        }

        &-teus {
          width: 20%;
          display: inline-block;
        }
      }

      .heading {
        width: 17%;
        display: inline-block;
      }

      .value {
        width: 83%;
        display: inline-block;
      }

      .values {
        width: 60%;
        display: inline-block;

        &-teu {
          width: 80%;
          display: inline-block;
        }
      }
    }
  }
}

.schedule-dating-port-dialog {
  .box-border {
    border: 1px solid $black !important;
    overflow: auto;
    height: 200px;
  }

  &-alignment {
    margin: 11px;
  }

  &-header {
    margin-bottom: 4%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-label-content {
    width: 240px !important;
  }

  .port-align {
    float: right;
  }

  .add-port-dialog-buttons {
    margin-left: 38% !important;
    margin-top: 10px !important;
  }

  .mat-mdc-radio-button {
    width: 100%;
  }
}

.mat-primary {
  .mat-mdc-option {
    &.mat-mdc-selected {
      /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
      &:not(.mat-option-disabled) {
        color: $black;
      }
    }

    .mat-pseudo-checkbox-checked {
      background: $seafoam-blue;
    }
  }

  &.range-dropdown {
    overflow-y: hidden;

    &-open-range {
      width: 190px !important;

      .custom-candidate {
        width: 44% !important;
      }
    }

    .range-input {
      display: inline-block;
      width: 40%;
      color: $black;
      height: 25px;
      overflow: hidden;
      margin: 10px;
      position: relative;
      border: 1px solid $warm-grey;
      padding: 0 5px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &:first-child {
        margin-right: 0;
      }

      svg-icon {
        &:host {
          position: absolute;
          margin-top: 16px !important;
        }
      }
    }

    .option-align-db {
      padding-left: 10px;
      cursor: pointer;
    }

    .option-align-relative {
      position: relative;
      top: 5px;
    }

    .height-zero {
      height: 0;
    }
  }

  &.range-teu-dropdown {
    overflow-y: auto;

    .range-input {
      display: inline-block;
      width: 40%;
      color: $black;
      height: 25px;
      overflow: hidden;
      margin: 10px;
      position: relative;
      border: 1px solid $warm-grey;
      padding: 0 5px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &:first-child {
        margin-right: 0;
      }

      svg-icon {
        &:host {
          position: absolute;
          margin-top: 16px !important;
        }
      }
    }
  }
}

.no-spinner {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-search-input {
  padding: 8px 36px 8px 16px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-search-clear {
  top: -4px !important;
}

.error {
  padding: 0 0 8px 20px;
}

.reverse {
  &-heading {
    padding: 8px 0 8px 16px;
    background: $lighter-grey;
  }

  &-context {
    padding: 8px 0 8px 16px;
  }

  &-buttons {
    text-align: right;
    padding: 8px 16px 8px 0;
  }
}

.position-option {
  .number {
    width: 5%;
    display: inline-block;
    text-align: center;
  }

  .vessel-code {
    color: $warm-grey;
    width: 8%;
    display: inline-block;
  }

  .vessel-code {
    color: $warm-grey;
    width: 8%;
    display: inline-block;
  }

  .vessel-code {
    color: $warm-grey;
    width: 8%;
    display: inline-block;
  }

  .current {
    color: $warm-grey;
    width: 17%;
    display: inline-block;
  }

  .vessel-name {
    display: inline-block;
    width: 42%;
  }

  .ends {
    width: 9%;
    display: inline-block;
    color: $warm-grey;
  }

  .date {
    width: 19%;
    display: inline-block;
  }
}

.pad-right {
  padding-right: 12px !important;
}

.pad-up {
  padding-top: 8px;
}

.recieved {
  .mat-mdc-form-field {
    &-flex {
      padding-right: 5px;
      padding-left: 5px;
      border: 1px solid $warm-grey;
    }

    &-infix {
      border: 0;
    }

    &-underline {
      display: none;
    }

    &-should-float {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label {
        display: none;
      }
    }
  }

  //  padding: 16px;

  &-day {
    padding: 7px 0;
  }

  &-comment {
    padding: 7px 0;

    &-box {
      padding: 7px 0;

      textarea {
        &.text-area {
          overflow: auto;
          resize: none;
          border: 1px solid $warm-grey;
          border-radius: 2px;
          width: 100%;
        }
      }
    }
  }

  &-date {
    padding: 7px 0;

    .date {
      padding-right: 15px;
    }

    input {
      &.input-box {
        border: 1px solid $warm-grey;
        border-radius: 3px;
        padding: 4px;
        width: 40%;
      }
    }
  }

  &-buttons {
    padding: 7px 0;
    text-align: right;

    .cancel {
      cursor: pointer;
    }

    .save-recieve {
      cursor: pointer;
      padding: 6px;
      background: $seafoam-blue;
      color: $white;
      border-radius: 5px;
      margin-left: 5px;
    }
  }
}

.redelivery-date-error {
  display: inline-block;
  margin-left: 0%;
}

.redelivery-date-button {
  margin-left: 32% !important;
  margin-top: 1% !important;
}

.redelivery-port-row {
  margin-left: 0px;
}

.redelivery-port-error {
  padding-left: 6%;
  padding-top: 11%;
}

.redelivery-port-button {
  margin-left: 32% !important;
  margin-top: 10% !important;
  float: right;
}

.recieve {
  .mat-mdc-form-field {
    &-flex {
      padding-right: 5px;
      padding-left: 5px;
      border: 1px solid $warm-grey;
    }

    &-infix {
      border: 0;
    }

    &-underline {
      display: none;
    }

    &-should-float {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label {
        display: none;
      }
    }
  }

  padding: 16px;

  &-day {
    padding: 7px 0;
  }

  &-comment {
    padding: 7px 0;

    &-box {
      padding: 7px 0;

      textarea {
        &.text-area {
          overflow: auto;
          resize: none;
          border: 1px solid $warm-grey;
          border-radius: 2px;
          width: 100%;
        }
      }
    }
  }

  &-date {
    padding: 7px 0;

    .date {
      padding-right: 15px;
    }

    input {
      &.input-box {
        border: 1px solid $warm-grey;
        border-radius: 3px;
        padding: 4px;
        width: 40%;
      }
    }
  }

  &-buttons {
    padding: 7px 0;
    text-align: right;

    .cancel {
      cursor: pointer;
    }

    .save-recieve {
      cursor: pointer;
      padding: 6px;
      background: $seafoam-blue;
      color: $white;
      border-radius: 5px;
      margin-left: 5px;
    }
  }
}

.blackouts-form {
  padding: 16px;

  .mat-mdc-form-field {
    width: 100%;
    margin-top: 3px;

    &-wrapper {
      padding: 0;
    }

    &-flex {
      border: solid 1px $warm-grey;
      border-radius: 3px;
    }

    &-infix {
      border: 0;
      padding: 0.35em 0;

      input {
        padding-left: 12px;
        padding-right: 12px;
        line-height: 12px;
      }
    }

    .mat-mdc-form-field-icon-suffix{
      white-space: nowrap;
      flex: none;
      .custom-close-icon-button{
        height: 12px;
        min-width: 12px;
        padding: 0px;
        right: -14px;
        top: 5px;
      } 
      mat-datepicker-toggle{
        .mat-mdc-icon-button.mat-mdc-button-base {
          --mdc-icon-button-state-layer-size: 24px;
        }
      }
    }

    &-label-wrapper {
      display: none;
    }

    &-underline {
      display: none;
    }

    textarea {
      &.mat-mdc-input-element {
        padding: 2px 16px;
      }
    }
  }

  .row {
    margin: 0 !important;
    border-bottom: 1px solid $white-two;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &-title {
    padding: 7px 0;
    font-weight: bold;
  }

  &-from-date,
  &-to-date {
    &-title {
      display: inline-block;
      width: 40%;
    }

    &-value {
      display: inline-block;
      width: 60%;
    }

    .date {
      padding-right: 15px;
    }

    input {
      &.input-box {
        border: 1px solid $warm-grey;
        border-radius: 3px;
        padding: 4px;
        width: 40%;
      }
    }
  }

  &-reason-value {
    width: 100%;

    textarea {
      overflow: auto;
      resize: none;
      border: 1px solid $warm-grey;
      border-radius: 2px;
      width: 100%;
    }
  }

  &-delete {
    display: inline-block;
    width: 20%;
    padding: 1%;
  }

  &-buttons {
    display: inline-block;
    width: 100%;
    padding: 7px 0;
    text-align: right;

    .cancel {
      cursor: pointer;
    }

    .save-blackouts {
      cursor: pointer;
      padding: 6px;
      background: $seafoam-blue;
      color: $white;
      border-radius: 5px;
      margin-left: 5px;
    }
  }
}

.send-notice {
  &-content {
    &.mat-mdc-dialog-content {
      max-height: none !important;
    }
  }

  &-container {
    .row {
      border-bottom: 1px solid $warm-grey;

      &:last-child {
        border-bottom: 0;
        //padding: 4px 0;

        [class*='col-'] {
          &:last-child {
            line-height: inherit;
          }
        }
      }

      .label {
        color: $warm-grey;
      }

      [class*='col-'] {
        min-height: 32px;
        line-height: 26px;

        span,
        a {
          width: 100%;
          display: inline-block;
          vertical-align: middle;
          line-height: normal;
        }
      }
    }

    .mat-mdc-form-field {
      width: 100%;
      margin-top: 3px;

      &-wrapper {
        padding: 0;
      }

      &-flex {
        border: solid 1px $warm-grey;
        border-radius: 3px;
      }

      &-infix {
        border: 0;
        padding: 0.35em 0;

        input {
          // padding-left: 12px;
          padding-right: 12px;
          line-height: 12px;
        }
      }

      &-label-wrapper {
        display: none;
      }

      &-underline {
        display: none;
      }
    }
  }
}
//open fixture - expenses
.delivery-card{
  .mat-mdc-form-field.custom{ //Recurrent Lumpsums
    .mat-mdc-text-field-wrapper {
      border-radius: 3px;
      padding-bottom: 0;
      border: 1px solid #888888;
      padding: 0px;
    }
    .mat-mdc-form-field-icon-suffix{
      white-space: nowrap;
      flex: none;
      padding: 0px;
      .custom-close-icon-button{
        height: 12px;
        min-width: 0px;
        padding: 0px;
        right: -7px;
        top: 5px;
        width: 17.4px;
      } 
    }
    .mat-mdc-form-field-subscript-wrapper{
      display: none
    }
  } 
  .mat-mdc-text-field-wrapper {
    // border: 1px solid #888;
    // padding: 0px;
    // border-radius: 3px;
    .mat-mdc-form-field-infix {
      width: 100%;
    }
    .mat-mdc-form-field-icon-suffix{
      white-space: nowrap;
      flex: none;
      // .custom-close-icon-button{
        // height: 12px;
        // min-width: 12px;
        // padding: 0px;
        // right: -13px;
        // top: 5px;
      // } 
      .mat-mdc-icon-button.mat-mdc-button-base {
        top: 0px;
        padding: 12px;
      }
    }
  }
  // .mat-mdc-form-field-subscript-wrapper{
  //   display: none;
  // }
  mat-select{ 
    .mat-mdc-select-arrow svg { 
      display: inline; 
      margin-left: -5px;
    }
  }
  .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    bottom: 1px;
  }
}
.portselect-container {
  .mat-mdc-tab {
    &-label {
      height: 36px;
      min-width: 148px;
    }

    &-body-content {
      overflow-x: hidden;
    }
  }

  .champs-custom-datepicker {
    &-content {
      box-shadow: none;

      .mat-calendar {
        height: 320px;

        &-header {
          padding-top: 0;
        }

        &-controls {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .port-selection {
    padding: 16px;

    [class*='vess-'] {
      display: inline-block;
      vertical-align: bottom;
    }

    .vess-arr,
    .vess-dep {
      width: 45px;
    }

    .vess-port {
      width: 140px;
    }
  }
}

.expand-container {
  .mat-mdc-dialog-container {
    padding: 0 !important;

    >div {
      position: relative;
      height: 100%;
    }

    .expand {
      &-content {
        height: 86%;
        overflow-y: auto;
        padding: 10px 10px 0 14px;
      }

      &-footer {
        position: absolute;
        bottom: 0;
        padding: 0 15px;
        background: $white;
        height: 30px;
        width: 100%;

        a,
        span {
          position: absolute;
          display: inline-block;
          line-height: 30px;
        }
      }
    }

    .mat-expansion {
      &-panel {
        position: relative;

        &:not([class*=mat-elevation-z]) {
          box-shadow: none !important;
        }

        &-spacing {
          margin: 0;
        }

        &-header {
          border-bottom: 1px solid $warm-grey;
        }

        &-content {
          &.mat-expanded {
            .mat-expansion-panel-body {
              display: block;
            }
          }
        }

        &-body {
          padding: 0;
          display: none;

          span {
            display: inline-block;
            width: 100%;
            padding-bottom: 5px;
            padding-left: 24px;

            &:first-child {
              padding-top: 5px;
            }
          }
        }

        .delete-link {
          position: absolute;
          right: 0;
        }
      }

      &-indicator {
        position: absolute;
        left: 10px;
        padding-bottom: 7px;

        &::after {
          border-width: 0 1px 1px 0 !important;
        }
      }
    }

    .ng-select {
      .ng-value {
        display: none !important;
      }

      .ng-select-container {
        .ng-arrow-wrapper {
          bottom: 6px;
        }
      }

      .ng-dropdown-panel {
        .scroll-host {
          max-height: 196px;
        }
        box-shadow: none;
      }
    }
  }
}

.dialog-container {
  font-size: .75rem;
  overflow: auto;
  mat-select{ 
    .mat-mdc-select-arrow svg { display: inline; } 
  }
  hr {
    margin: 0;
    background-color: $warm-grey;
  }

  .mat-mdc-form-field {
    display: block;

    &.inspection-port {
      margin-bottom: -10px;
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 24px;
    }
  }

  .create-form {
    &-header {
      font-size: 12px !important;
      font-weight: bold;
      padding: 10px 20px;
    }

    &-body {
      padding: 10px 20px;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix {
        border-top: 0 !important;
      }
    }

    &-footer {
      padding: 10px 20px;

      >div {
        text-align: right;
      }
    }
  }

  .button-create {
    background: $seafoam-blue;
    color: $white;
    max-height: 36px;
    margin-left: 8px;
  }

  .button-cancel {
    color: $black !important;
    cursor: pointer;
  }
}

.cdk-overlay-pane {
  .mat-mdc-dialog {
    &-container {
      // padding: 16px;
      overflow-x: hidden;
      border: 1px solid grey;
      .mdc-dialog__surface {
        padding: 16px;
        .recieve .mat-mdc-text-field-wrapper.mdc-text-field { //notices-dashboard
          padding: 0px;
        }
      }
    }

    &-content {
      max-height: calc(100vh - 60px);
      min-height: calc(100% - 60px);
      margin: 0 -16px;
      padding: 0 16px;
      .mat-mdc-card-outlined {
        border-width: 0
      }
    }

    &-actions {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: -16px;

      &[align='space'] {
        justify-content: space-between;
      }
    }
  }
  div.mat-mdc-autocomplete-panel {
    min-width: 112px;
  }
}

.fixture-overlay-container {
  .mat-mdc-card {
    padding: 0;
    box-shadow: none !important;

    &-header-text {
      margin: 0;
    }

    &-title {
      font-weight: bold !important;
    }

    .material-select {
      >div {
        &:first-child {
          max-width: calc(16.66666667% - 5px);
        }

        &:last-child {
          flex: 0 0 calc(83.33333333% + 5px);
          max-width: calc(83.33333333% + 5px);
        }
      }
    }

    .ng-dropdown-panel {
      &-items {
        max-height: 110px !important;
      }
    }

    label,
    mat-form-field {
      &+span {
        display: inline-block;
        width: 28px;
      }
    }

    .form-border {
      min-height: 32px;
      border-bottom: 1px solid $disabled-grey;
      margin-bottom: 4px;
      padding-bottom: 0;
    }

    .date-range {
      .mat-mdc-form-field {
        width: calc(50% - 10px) !important;

        &.date {
          width: 50% !important;
          display: inline-block;
        }

        &.time {
          width: 20% !important;
          display: inline-block;
        }
      }
    }

    .margin-to-align {
      margin-left: -28px;
    }
  }

  .block-form {
    display: inline-flex;

    .mat-mdc-form-field {
      flex-grow: 1;
    }
  }

  hr {
    margin-left: -16px;
    margin-right: -16px;
  }

  .margin-top-15 {
    margin-top: 15px;
  }
}

.ng-select {
  &.custom-candidate {
    padding: 0 !important;
    margin-bottom: 10px;
    width: 188px;
    height: 26px;

    .ng-select-container {
      min-height: 24px;
      border-radius: 0;
      border: 1px solid $warm-grey;
      background-color: $white;

      &::after {
        content: none !important;
        display: none;
      }

      >div {
        padding: 1px 3px;
        margin: 0;
        height: 22px;
        border: 0;
      }

      .ng-input {
        bottom: inherit;
        padding: 0 4px;
      }

      .ng-arrow-wrapper {
        display: none;
      }

      .ng-clear-wrapper {
        display: none;
      }

      .ng-value-container {
        .ng-value {
          font-size: inherit !important;
          display: inline-flex !important;
          background-color: $white;
          color: $black;
          padding: 0;
          margin-bottom: 0;
        }

        &.ng-value-icon {
          cursor: none;
        }
      }
    }

    .ng-dropdown-panel {
      &.ng-select-bottom {
        top: inherit;
      }

      &.ng-select-top {
        bottom: 100%;
      }
    }

    .country-name {
      margin-left: 70px;
    }
  }
}

.hire-statement {
  &-resend-text {
    margin-left: -8px;
  }

  &-resend-buttons {

    // margin-top: 40px;
    &-save-wide {
      background: $seafoam-blue-three;
      color: $white;
      border-radius: 5px !important;
      float: right;
      margin: 10px !important;
      width: 9.7rem;
      height: 2.3rem;
    }

    &-cancel-wide {
      background: $white;
      color: $light-black;
      border-radius: 5px !important;
      width: 9.7rem;
      height: 2.3rem;
      float: left;
      margin: 10px !important;
      border: 1px $light-black solid !important;
    }
  }

  &-margin {
    margin-left: 45px;
  }

  &-loading-img-margin {
    margin-left: 100px;
    margin-bottom: 10px;
  }
}

.background-col {
  background: grey !important;
}

.background-col-white {
  background: white !important;
}

.text-right {
  text-align: right;
  display: inline-block;
}

.un-hide {
  display: inline-block !important;
}

.hide {
  display: none;
}

.div-cut-popup {
  border: 1px solid orange;
  width: 489px !important;
  opacity: 1;
  position: absolute;
  left: 0;
  background: orange;
  color: white;
  height: auto;
  z-index: 3000;
}

.redeliver-vessel {
  .row-lable-date-picker {
    display: inline-block;
    width: 43%;
  }

  .mat-mdc-form-field {
    &.date {
      width: 60% !important;
      display: inline-block;
    }

    &.time {
      width: 30% !important;
      display: inline-block;
    }

    display: inline-block;
    position: relative;
    font-size: 12px;
    text-align: left;
    width: 100%; // padding-left: 10px;
    padding-top: 10px;

    &-wrapper {
      padding-bottom: 0;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    &-flex {
      border: 1px solid $white-grey;
      margin: 0 -50px 0 0;
      padding: 0 5px;
      background: $white;
    }

    &-underline {
      display: none;
    }

    &-infix {
      border: 0;
    }

    &-hide-placeholder {
      .mat-mdc-select {
        &-panel {
          width: 156px;
        }

        &-content {
          .teu-inputs {
            width: 50px;
            margin: 10px;
          }

          img {
            float: right;
            margin-right: 25px;
          }
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-placeholder {
        color: $black !important;
      }
    }

    &-suffix {
      img {
        margin-left: -17px;
        width: 16px;
        height: 16px;
        margin-bottom: -3.5px;
        background: $white;
      }
    }
  }

  .ng-select {
    &.filter {
      width: 282px;
      margin-right: 5px;
      background-color: $white;
      border: 1px solid $white-grey;
      padding: 0 !important;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 5px;
      zoom: 1;

      .ng-select-container {
        min-height: 24px;
        background-color: $white;

        &::after {
          content: none !important;
          display: none;
        }

        >div {
          padding: 4px 3px;
          margin: 0;
          height: 22px;
          border: 0;
        }

        .ng-input {
          bottom: inherit;
          padding: 0 4px;

          input {
            cursor: text;
          }
        }

        .ng-placeholder {
          bottom: 4px;
        }

        .ng-value {
          display: inline;
        }

        .ng-arrow-wrapper {
          display: none;
        }

        .ng-clear-wrapper {
          display: none;
        }
      }

      .ng-dropdown-panel {
        &.ng-select-bottom {
          top: inherit;
        }

        &.ng-select-top {
          bottom: 100%;
        }
      }
    }
  }

  .value {
    display: inline-block;
    width: 50%;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.disabled-port {
  color: $warm-grey !important;
  background: $border-grey !important;
}

// this is for the more actions dialogs in fixture header
.more-actions-dialog {
  padding: 16px;

  .mat-mdc-form-field {
    width: 100%;

    &-wrapper {
      padding-bottom: 0;
    }

    &-flex {
      border: 1px solid $warm-grey;
      border-radius: 3px;
      width: 100%;
    }

    &-infix {
      padding: 4px 0 !important;
      border: 0;
    }

    &-underline {
      display: none;
    }

    &-should-float {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label {
        display: none;
      }
    }
  }

  &-title {
    font-size: 18px;
  }

  &-to {
    display: flex;
    margin-top: 18px;

    &-title {
      display: inline-block;
      width: 20%;
      color: $warm-grey;
    }

    &-value {
      display: inline-block;
      width: 80%;
    }
  }

  &-cc {
    display: flex;
    margin-top: 10px;

    &-title {
      display: inline-block;
      width: 20%;
      color: $warm-grey;
    }

    &-value {
      display: inline-block;
      width: 80%;

      textarea {
        width: 100%;
      }
    }
  }

  &-extra {
    display: flex;
    margin-top: 10px;

    &-title {
      display: inline-block;
      width: 20%;
    }

    &-value {
      display: inline-block;
      width: 80%;

      textarea {
        width: 100%;
      }
    }
  }

  &-subject {
    display: flex;
    margin-top: 10px;

    &-title {
      display: inline-block;
      width: 20%;
      color: $warm-grey;
    }

    &-value {
      display: inline-block;
      width: 80%;

      textarea {
        width: 100%;
      }
    }
  }

  &-content {
    display: flex;
    margin-top: 10px;

    &-title {
      display: inline-block;
      width: 20%;
    }

    &-value {
      display: inline-block;
      width: 100%;

      textarea {
        width: 100%;
      }
    }
  }

  &-buttons {
    margin-top: 10px;
    position: relative;
    height: 30px;

    &-cancel {
      position: absolute !important;
      right: 14% !important;
      background-color: $white !important;
      color: $black !important;
    }

    &-save {
      position: absolute !important;
      right: 0% !important;
      background-color: $seafoam-blue !important;
    }
  }
}

// this is for delete fixture confirm dialog
.delete-fixture-confirm {
  &-title {
    text-align: center;
  }

  &-buttons {
    text-align: center;

    &-cancel {
      color: $black;
    }

    &-delete {
      background-color: $seafoam-blue !important;
      color: $white;
    }
  }
}

.issues-card {
  &-heading-font {
    font-size: 20px;
  }
}

.replace-dialog-container {
  font-size: .75rem;
  overflow: auto;
  position: fixed !important;
  top: 10%;
  z-index: 1000;
  min-height: 365px;
  max-height: 85%;

  hr {
    margin: 0;
    background-color: $warm-grey;
  }

  .mat-mdc-form-field {
    display: block;

    &.inspection-port {
      margin-bottom: -10px;
    }
  }

  .create-form {
    &-header {
      font-size: 12px !important;
      font-weight: bold;
      padding: 10px 20px;
    }

    &-body {
      padding: 10px 20px;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix {
        border-top: 0 !important;
      }
    }

    &-footer {
      padding: 10px 20px;

      >div {
        text-align: right;
      }
    }
  }

  .button-create {
    background: $seafoam-blue;
    color: $white;
    max-height: 36px;
    margin-left: 8px;
  }

  .button-cancel {
    color: $black !important;
    cursor: pointer;
  }
}

.issues-card {
  .header-align-fix {
    margin-left: -8px;
    font-size: 18px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .color-text-grey {
    color: #888888;
  }

  .text-area-width {
    width: 100%;
    resize: vertical;
    max-height: 321px;
  }

  .padding-top-8 {
    padding-top: 8px;
  }

  .padding-10 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .update-btn-lnk {
    border: 1px solid $dusk-blue;
    border-radius: 5px;
    padding: 0px 5px;
  }

  .padding-right-70 {
    padding-right: 70px;
  }

  .label-span-grey {
    color: $warm-grey;
  }
  .label-span-red {
    color: red;
    font-weight: bold;
  }

  .align-right {
    text-align: right;
  }

  .float-right {
    float: right;
  }

  .text-green {
    color: $green-two;
  }
  .text-center{
    text-align: center;
  }
}

// used in unemployed vessels
.teu-search-icon {
  position: relative !important;
  top: 5px !important;
}

.teu-option {
  height: 0 !important;
}

//Vessel-list-component
.CreateVessel{
  .mat-mdc-select-arrow svg{
    display: inline;
  }
  .mat-mdc-form-field-flex {
    border-bottom: 1px solid black;
  }
  .mdc-text-field{
    padding: 0px;
  }
  .mat-mdc-form-field-error-wrapper {
    border-top: 1px solid red;
    
  }
}

.confirmation-dialog{
  .mat-mdc-form-field{
    .mdc-text-field{
      padding: 0px;
    }
    .mat-mdc-form-field-icon-suffix{
      white-space: nowrap;
      flex: none;
      .custom-close-icon-button{
        height: 12px;
        min-width: 12px;
        padding: 0px;
        right: -13px;
        top: 5px;
      }
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  bottom: 2px; //added for dictionary-stakeholder form 
}
// end
